import { defineAppConfig } from '@nsf/use/composables/defineAppConfig.js'
import config from '../index.mjs'
import features from './features.mjs'

export default defineAppConfig({
  ...features,
  apiKey: 'cc5f763fc3c100ff19beaa64944ed882',
  analyticsApiKey: '8ed541212e9298be42d99a72470adc50',
  ...config,
})
