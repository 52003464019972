export default defineAppConfig({
  ssrCache: {
    metadata: {
      be: {
        code: 'magento',
        region: 'SVK'
      },
      fe: {
        businessUnit: 'SVK',
        code: 'nsf-drmax',
        region: 'SVK'
      }
    }
  }
});