export default {
  global: {
    agreementsTopic: 'CP',
    city: 'Bratislava',
    country: 'Slovensko',
    langIso: 'sk-SK',
    countryIso: 'SVK',
    xTenant: 'SK',
    loqateApiKey: 'ZH66-WZ79-JC37-RU85',
    pagination: {
      productsPerPage: 24,
    },
    pharmacyTypes: [],
    phoneCode: '+421',
    phoneCodes: ['+420', '+421'],
    phoneValidations: ['^\\d{9}$', '^([9]{1})(\\d{8})$'],
    storeName: 'Dr.Max',
    validationPhone: '^[1-9][0-9]{8}$',
    zipLength: 5,

    /**
     * Global features - used across multiple common modules
     */
    advancedAgreementsEnabled: false,
    billingInformationAddressEnabled: false,
    companyBillingAddressEnabled: true,
    competitionServiceTimeBasedEnabled: false,
    crnEnabled: true,
    loggedPriceInfoEnabled: true,
    loyaltyCardEnabled: true,
    loyaltyCardPlusPrefixEnabled: false,
    loyaltySavingsEnabled: false,
    multiStoreEnabled: false,
    myAccountCouponsEnabled: false,
    myAccountAmazonEnabled: false,
    myAccountAmazonFromPlacementApi: false,
    myAccountAmazonAnotherOffers: false,
    myAccountOtherOffersButtonEnabled: false,
    newUrlResolverEnabled: true,
    otcFormEnabled: false,
    phoneCodeEnabled: true,
    phoneCodeSelectEnabled: false,
    phoneCodeSentSeparatelyEnabled: true,
    regionSelectEnabled: false,
    savingsAndBenefitsInHeaderEnabled: false,
    skDicEnabled: true,
    splitStreetNumberBeforeSavingEnabled: false,
    stockStatusQuantityCheckEnabled: false,
    streetNumberEnabled: true,
    vatIdEnabled: true,
    vatIdRequired: false,

    /**
     * Google Maps API
     * API Keys: https://mydrmax.atlassian.net/wiki/spaces/GLECOM/pages/1600290889/API+keys+-+Google+Maps
     * Supported languages: Subset of ISO 639-1, see https://developers.google.com/maps/faq#languagesupport
     */
    maps: {
      apiKey: 'AIzaSyBpPdwcxyUmKhnMbCzRTk8auqn4hFpQ6XA',
      interfaceLanguage: 'sk',
      myLocationIcon: 'map-my-location-sk.png',
      defaultLocation: {
        latitude: 48.148598,
        longitude: 17.107748,
      },
    },

    watchdog: {
      stockAlertEnabled: true,
      priceAlertEnabled: true,
      forceLoginEnabled: false,
    },
  },
}
