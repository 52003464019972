const pickupInstructions = [
  {
    icon: 'shipping',
    fragments: [
      { text: 'Dodanie na odberné miesto Packety z' },
      {
        text: 'online obchodu',
        isBold: true,
      },
    ],
  },
  {
    icon: 'sms',
    fragments: [
      {
        text: 'Hneď ako bude Vaša objednávka pripravená na vyzdvihnutie, Packeta Vás bude kontaktovať SMS správou, alebo E-mailom.',
        isBold: true,
      },
    ],
  },
  {
    icon: 'calendar',
    fragments: [
      { text: 'Objednávku si môžete vyzdvihnúť' },
      {
        text: 'maximálne do 7 dní od jej doručenia na odberné miesto.',
        isBold: true,
      },
    ],
  },
]

export default {
  confirmButtonLabel: 'Vyberte odberné miesto',
  orderLabel: 'Objednávka na odberné miesto',
  selectedPlaceLabel: 'Vybrané odberné miesto',
  unavailablePickupLabel: 'Na toto miesto nie je možné objednať',

  getTitle() {
    return this.title
  },
  getSubtitle() {
    return [this.location.zipCode, this.location.city].filter(Boolean).join(' ')
  },
  getInstructions() {
    return {
      place: this.location.description
        ? [
            {
              icon: 'info',
              fragments: [{ text: this.location.description }],
            },
          ]
        : [],
      pickup: pickupInstructions,
    }
  },
  getReviewAddress() {
    return this.location.street
  },
  pins: {
    enabled: 'pin-packeta-enabled.svg',
    disabled: 'pin-packeta-disabled.svg',
    selected: 'pin-packeta-selected.svg',
    loading: 'pin-loading.svg',
  },
  clusterFillColor: '#BA1B02',
  icon: '/img/delivery/packeta.svg',
}
