export default {
  agreementsSchema: [
    {
      label: 'offers and announcements',
      description: '',
      agreements: [
        {
          label: 'Email',
          description: 'loyalty mail agreement description',
          channel: 'EMAIL',
          topic: 'ESHOP',
          sorpAvailabilityOptions: {
            activeAccount: true,
            fullAccount: false,
          },
        },
        {
          label: 'SMS',
          description: 'loyalty sms agreement description',
          channel: 'SMS',
          topic: 'ESHOP',
          hasPhoneNumber: true,
          sorpAvailabilityOptions: {
            activeAccount: true,
            fullAccount: false,
          },
        },
      ],
    },
    {
      label: 'offers and announcements',
      description: '',
      agreements: [
        {
          label: 'Email',
          description: 'loyalty mail agreement description',
          channel: 'EMAIL',
          topic: 'ESHOP',
          sorpAvailabilityOptions: {
            activeAccount: true,
            fullAccount: true,
          },
        },
        {
          label: 'SMS',
          description: 'loyalty sms agreement description',
          channel: 'SMS',
          topic: 'ESHOP',
          hasPhoneNumber: true,
          sorpAvailabilityOptions: {
            activeAccount: true,
            fullAccount: true,
          },
        },
      ],
    },
    {
      label: 'Drmax offers and loyalty program announcements',
      description: '',
      agreements: [
        {
          label: 'Email',
          description: 'loyalty mail agreement description',
          channel: 'EMAIL',
          topic: 'CP',
          sorpAvailabilityOptions: {
            activeAccount: true,
            fullAccount: true,
          },
        },
        {
          label: 'SMS',
          description: 'loyalty sms agreement description',
          channel: 'SMS',
          topic: 'CP',
          hasPhoneNumber: true,
          sorpAvailabilityOptions: {
            activeAccount: true,
            fullAccount: true,
          },
        },
        {
          label: 'Post',
          description: 'loyalty post agreement description',
          channel: 'POST',
          topic: 'CP',
          sorpAvailabilityOptions: {
            activeAccount: true,
            fullAccount: true,
          },
        },
      ],
    },
    {
      label: 'Other consents',
      description: '',
      agreements: [
        {
          label: 'Agreement for partners',
          description: 'email-sms-text',
          channel: '3RD',
          topic: 'CLIENT',
          sorpAvailabilityOptions: {
            activeAccount: true,
            fullAccount: false,
          },
        },
      ],
    },
    {
      label: 'Other consents',
      description: '',
      agreements: [
        {
          label: 'Agreement for partners',
          description: 'email-sms-text',
          channel: '3RD',
          topic: 'CLIENT',
          sorpAvailabilityOptions: {
            activeAccount: true,
            fullAccount: true,
          },
        },
        {
          label: 'Consent to sending an electronic receipt to an email',
          description: 'eblok-long-text',
          channel: 'EBLOK',
          topic: 'CLIENT',
          sorpAvailabilityOptions: {
            activeAccount: true,
            fullAccount: true,
          },
        },
      ],
    }
  ]
}
