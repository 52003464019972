export default {
  footer: {
    footerTopLogos: [
      {
        src: '/images/footer/sukl.png',
        alt: 'SUKL overenie',
        link: 'https://www.sukl.sk/hlavna-stranka/slovenska-verzia/inspekcia/lekarenstvo/internetovy-vydaj-liekov-a-zdravotnickych-pomocok/zoznam-subjektov-vykonavajucich-internetovy-vydaj?page_id=4380',
        width: 74,
        height: 73,
      },
      {
        src: '/images/footer/Saec.png',
        alt: 'SAEC logo',
        link: 'https://www.bezpecnynakup.sk/sk/certifikovane-obchody',
        width: 51,
        height: 70,
      },
    ],
    emailSubscription: false,
    paymentsImages: [],
    suklImages: false,
    footerTopAbsoluteImages: [],
    footerTopAbsoluteImagesHeight: 0,
    feedatyWidget: false,
    feedatyMerchantId: '',
    useFooterCertificateBlock: false,
    footerCertificateText: '',
    footerCertificateTextClass: '',
    footerCertificateImages: [],
    flags: true,
    mobileApplications: false,
  },
}
