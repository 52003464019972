import { defineAppConfig } from '@nsf/use/composables/defineAppConfig.js'

const validateSkCRN = (value) => {
  const inputDigits = value.split('')

  const checkSum = inputDigits
    .slice(0, 7)
    .reduce((accumulator, digit, index) => accumulator + (8 - index) * +digit, 0)

  const checkSumRemainder = checkSum % 11
  const checkDigit = (11 - checkSumRemainder) % 10

  return +inputDigits[7] === checkDigit
}

export default defineAppConfig({
  cityMaxLength: 30,
  companyNameMaxLength: 70,
  emailMaxLength: 50,
  firstNameMaxLength: 35,
  lastNameMaxLength: 35,
  streetMaxLength: 40,

  cityAllowedCharacters: () => true,
  cityContainsLetter: () => true,
  customerNotesAllowedCharacters: () => true,
  firstLastNameAllowedCharacters: (value) => /^[a-zA-Z-'\s\u00C0-\u024F\u1E00-\u1EFF]*$/.test(value), // validator must be true for empty input. This happens if the field is not required
  streetAllowedCharacters: () => true,

  // eslint-disable-next-line no-use-before-define
  crnValid: (value) => validateSkCRN(value),
  crnNumbersOnly: (value) => /^[0-9]+$/.test(value),
  crnLengthValid: (value) => value.length === 8,
  crnLength: 8,

  passwordForbiddenKeywords: ['heslo'],

  postCodeValid: (value) => /^[0-9]{3}\s?[0-9]{2}$/.test(value),

  streetValid: () => true,
  streetNumberValid: (value) => /^[0-9]+(\/?[0-9a-z]+)?$/i.test(value),
  streetNumberContainsNumber: () => true,

  validByFirstThreeDigits: () => true,
  validByForbiddenCode: () => true,

  vatIdLengthValid: (value) => value.length === 12,
  vatIdValid: (value) => /^SK[0-9]{10}$/.test(value),
  vatIdForbiddenWords: () => true,
  vatIdStartsWith: (value) => value.substring(0, 2) === 'SK',
  vatIdLength: 12,
})
