export default {
  allow: [
    ...process.env.ROBOTS_ALLOW?.split('|') || [],
    '*&menu=*',
    '*&rating=*',
    '*?amp*',
    '*?amp%3Brefresh*',
    '*?cjdata*',
    '*?fbclid*',
    '*?fresh*',
    '*?gbraid*',
    '*?gclid*',
    '*?menu=*',
    '*?network*',
    '*?page*',
    '*?p=*',
    '*?rating=*',
    '*?refresh*',
    '*?refreshing*',
    '*?ruleid*',
    '*?sku=*',
    '*?utm*',
    '*?y_source*',
    '/_i/*',
    '/i18n/*'
  ],
  disallow: [
    ...process.env.ROBOTS_DISALLOW?.split('|') || [],
    '/*?*',
    '*/_e*',
    '*/graphql*',
    '/moj-ucet/editacia$',
    '/moj-ucet/moje-nakupy$',
    '/moj-ucet/moje-oblubene$',
    '/moj-ucet$',
    '/moje-strazne-psy$',
    '/oblubene-produkty$',
    '/pokladna/*',
  ],
}
