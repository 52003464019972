export default {
  product: {
    alternateProductSeoDescription: false,
    defaultMaxQty: 1000,
    detail: {
      addAttributeSetToAttributes: false,
      apothekaWidgetEnabled: true,
      disableReviewSource: false,
      forcedAttributes: [],
      showFreeShippingValue: false,
      showLastUpdate: false,
      showLoggedPriceNote: true,
      showPriceInfo: true,
      showPriceNote: false,
      titleRowsSeparated: true,
      useFreeShippingThreshold: true,
      visibleAttributeCodes: [],
    },
    infoBoxEnabled: false,
    isAttributeSetShowed: false,
    reviewsEnabled: true,
    shortDescriptionShowedOn: [
      'eLeader',
      'category',
      'productDetail',
    ],
    wrapperProductPimFilter: [
      'Temporary unavailable',
      'Visible',
      'Permanently unavailable',
      'Delisted - sold off',
      'Promo',
    ],
  },
}
