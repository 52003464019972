import { defineAppConfig } from '@nsf/use/composables/defineAppConfig.js'

export default defineAppConfig({
  codes: [
    '72995289000',
    '29995053000',
    '04995082000',
  ],
  medicalProdIds: [13, 15],
  vetProdIds: [19],
})
