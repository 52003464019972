export default {
  footer: {
    certificates: [
      {
        image: {
          url: '/images/footer/sukl.png',
          alt: 'SUKL overenie',
          width: 91,
          height: 90,
        },
        link: 'https://www.sukl.sk/hlavna-stranka/slovenska-verzia/inspekcia/lekarenstvo/internetovy-vydaj-liekov-a-zdravotnickych-pomocok/zoznam-subjektov-vykonavajucich-internetovy-vydaj?page_id=4380',
      },
      {
        image: {
          url: '/images/footer/Saec.png',
          alt: 'SAEC logo',
          width: 51,
          height: 70,
        },
        link: 'https://www.bezpecnynakup.sk/sk/certifikovane-obchody',
      },
      {
        image: {
          url: '/images/footer/Best-buy-award-logo.png',
          alt: 'BestBuy cena',
          width: 102,
          height: 100,
        },
      },
    ],
    links: [
      {
        url: '/podmienky-internetoveho-vydaja',
        text: 'Podmienky internetového výdaja liekov, zdravotníckych pomôcok a doplnkového sortimentu',
      },
      {
        url: '/zasady-ochrany-osobnych-udajov-v-online-lekarni-dr-max',
        text: 'Zásady ochrany osobných údajov',
      },
      {
        url: '/moznosti-dopravy',
        text: 'Možnosti dopravy',
      },
      {
        url: '/moznosti-platby',
        text: 'Možnosti platby',
      },
    ],
  },
}
