export default {
  carrierCodes: {
    clickAndCollect: {
      pickupPlaceCarrierCode: 'drmaxpickupplace',
      driveInCollectCarrierCode: 'drmaxskshippingdrivein',
      packetaCollectCarrierCode: 'drmaxshippingpacketa',
      pharmacyCollectCarrierCode: 'drmaxclickandcollectshipping',
      pharmacyExpressCollectCarrierCode: 'drmaxclickandcollectexpress',
      postOfficeCollectCarrierCode: 'drmaxskshippingpostskpickup',

      drmaxBoxHookCode: 'drmax_box',
      pharmacyHookCode: 'pharmacy',
    },
    limitedByZipCode: {
      go4CarrierCode: 'drmaxskshippinggo4',
    },
    timeSlotDelivery: {},
  },
}
