/* eslint-disable prefer-rest-params */
/* eslint-disable no-underscore-dangle */
/* eslint-disable camelcase */
/* eslint-disable import/prefer-default-export */
/* eslint-disable no-param-reassign */
import { isNullish, isString } from '@nsf/core/Utils.js'
import { useAppConfig } from '@nsf/use/composables/useAppConfig.js'
import { useLogger } from '@nsf/use/composables/useLogger.js'
import { useRuntimeConfig } from '@nsf/use/composables/useRuntimeConfig.js'
import { ensureArray } from '@nsf/utils/ArrayUtils.js'
import { createQueryString } from '@nsf/utils/UrlUtils.js'
import flatMap from 'lodash/flatMap.js'
import isEmpty from 'lodash/isEmpty.js'
import qs from 'querystring'

const logger = useLogger('ElasticSearch')

const {
  rootConfig: {
    app: {
      prefix: {
        elastic: apiIndex,
      },
    },
    cloudflare: {
      accessClientId,
      accessClientSecret,
    },
    elastic: {
      index: onlyOneElasticIndex,
      indices: {
        allIndicesWildcard,
        apothekaPharmacyIndex,
        attributeIndex,
        brandIndex,
        bundleIndex,
        categoryIndex,
        deliveryCalculationConfigIndex,
        filterPageIndex,
        marketplaceSellersIndex,
        megamenuIndex,
        productAttachmentIndex,
        productGroupIndex,
        productIndex,
        productReviewsIndex,
        promoActionsIndex,
        salesRuleIndex,
        urlResolverIndex,
      },
    },
  },
} = useAppConfig()

const {
  appUrlInternal,
  public: {
    appUrl,
    elasticSplittedIndiciesEnabled,
  },
} = useRuntimeConfig()

export const QUERYABLE_PIM_STATUS = process.env.CORE_PRODUCTS_QUERYABLE_PIM_STATUS
  || 'Visible|Available|Special sale off|Temporary unavailable'
export const QUERYABLE_PIM_STATUS_AVAILABLE = process.env.CORE_PRODUCTS_AVAILABLE_QUERYABLE_PIM_STATUS
  || 'Available|Special sale off'
export const QUERYABLE_PIM_STATUS_PRODUCT_DETAIL = process.env.CORE_PRODUCT_DETAIL_QUERYABLE_PIM_STATUS
  || 'Visible|Available|Special sale off|Temporary unavailable|Permanently unavailable|Delisted - sold off'

const queryablePimStatus = QUERYABLE_PIM_STATUS.split('|')
const queryablePimStatusAvailable = QUERYABLE_PIM_STATUS_AVAILABLE.split('|')
const queryablePimStatusProductDetail = QUERYABLE_PIM_STATUS_PRODUCT_DETAIL.split('|')

/**
 * @callback QueryCallback
 * @param {Query} query
 */

/**
 * Query is class for querying records from elasticsearch.
 *
 * New query is initialized with one of the static methods (products, categories and brands)
 *
 * await Query.products() // query for products
 *   .only('id', 'name') // we only want id and name of the returned products
 *   .where('category_id', cateogryId) // and we only want products from this category
 *   .whereIn('drmax_brand', brandId1, brandId2, brandId3) // and only from these three brands
 *   .from(48) // and only products from 48th product (skip first 48 products)
 *   .get(24) // and 24 products per page
 */
export class Query {
  // Parameters with default values were added to improve testability, there was no DI at all at the beginning
  constructor(
    type,
    configFile,
    processServerMocked,
    fetchHandler = globalThis.fetch,
  ) {
    this._appUrl = configFile?.app?.url ?? appUrl
    this._type = type
    this._size = 10
    this._from = 0
    this._named = {}
    this._sort = []
    this._only = []
    this._omit = []
    this._should = []
    this._must = []
    this._must_not = []
    this._match = {}
    this._aggs = {}

    // _index will replace _type (type is only mapping for items in same ES index)
    // default value is still single index solution, will be removed in the future
    this._index = onlyOneElasticIndex // as default value, is overriden later via _setIndex() method
    this._fetchHandler = fetchHandler
    this._splittedIndiciesEnabled = configFile?.app?.elastic?.splittedIndiciesEnabled ?? elasticSplittedIndiciesEnabled
  }

  /**
   * Creates new query which can return anything
   *
   * @returns {Query}
   */
  static any() {
    return new Query(null)
      ._setIndex(allIndicesWildcard)
  }

  /**
   * Creates new query which returns url resolver
   *
   * @returns {Query}
   */
  static urlResolver() {
    return new Query('url_resolver', ...arguments)
      ._setIndex(urlResolverIndex)
  }

  /**
   * Creates new query for all products
   *
   * @returns {Query}
   */
  static productsUnfiltered() {
    return new Query('product', ...arguments)
      ._setIndex(productIndex)
  }

  /**
   * Creates new query for products only
   *
   * @returns {Query}
   */
  static products() {
    return new Query('product', ...arguments)
      ._setIndex(productIndex)
      .whereIn('drmax_pim_status', queryablePimStatus)
  }

  /**
   * Creates new query for available and special sale off products only
   *
   * @returns {Query}
   */
  static productsAvailable() {
    return new Query('product')
      ._setIndex(productIndex)
      .whereIn('drmax_pim_status', queryablePimStatusAvailable)
  }

  /**
   * Creates new query for product with different pim statuses.
   * e.g. on product detail we also want to show permanently unavailable product
   *
   * @returns {Query}
   */
  static product() {
    return new Query('product', ...arguments)
      ._setIndex([productIndex, bundleIndex])
      .must((y) => {
        y.should((x) => x.whereIn('drmax_pim_status', queryablePimStatusProductDetail))
        y.should((x) => x.notExists('drmax_pim_status'))
      })
  }

  static deliveryCalculationConfig() {
    return new Query('delivery_calculation_config', ...arguments)
      ._setIndex(deliveryCalculationConfigIndex)
  }

  static apothekaPharmacy() {
    return new Query('apotheka_pharmacy', ...arguments)
      ._setIndex(apothekaPharmacyIndex)
  }

  static salesRule() {
    return new Query('salesrule', ...arguments)
      ._setIndex(salesRuleIndex)
  }

  /**
   * Creates new query for categories only
   *
   * @returns {Query}
   */
  static categories() {
    return new Query('category', ...arguments)
      ._setIndex(categoryIndex)
      .whereNot('is_active', false)
  }

  /**
   * Creates new query for brands only
   *
   * @returns {Query}
   */
  static brands() {
    return new Query('brand', ...arguments)
      ._setIndex(brandIndex)
  }

  /**
   * Creates new query for attributes only
   *
   * @returns {Query}
   */
  static attributes() {
    return new Query('attribute', ...arguments)
      ._setIndex(attributeIndex)
  }

  /**
   * Creates new query for mega menus only
   *
   * @returns {Query}
   */
  static megaMenus() {
    return new Query('mega_menu', ...arguments)
      ._setIndex(megamenuIndex)
  }

  /**
   * Creates new query for product attachments only
   *
   * @returns {Query}
   */
  static productAttachments() {
    return new Query('product_attachment', ...arguments)
      ._setIndex(productAttachmentIndex)
  }

  /**
   * Creates new query for product reviews only
   *
   * @returns {Query}
   */
  static reviews() {
    return new Query('review', ...arguments)
      ._setIndex(productReviewsIndex)
  }

  /**
   * Creates new query for global alerts
   *
   * @returns {Query}
   */
  static globalAlert() {
    return new Query('global_alerts', ...arguments)
      ._setIndex(allIndicesWildcard)
  }

  /**
   * Creates new query for promo actions only
   *
   * @returns {Query}
   */
  static promoActions() {
    const today = new Date().toISOString()
      .slice(0, 10) // YYYY-MM-DD

    return new Query('promo_action', ...arguments)
      ._setIndex(promoActionsIndex)
      .lessThanEqual('date_from', today) // Started today or sooner
      .greaterThanEqual('date_to', today) // Ending today or later
  }

  /**
   * Creates new query for cms pages only
   *
   * @returns {Query}
   */
  static pages() {
    return new Query('cms_page', ...arguments)
      ._setIndex(allIndicesWildcard)
  }

  /**
   * Creates new query for articles only
   *
   * @returns {Query}
   */
  static articles() {
    return new Query('article', ...arguments)
      ._setIndex(allIndicesWildcard)
  }

  /**
   * Creates new query for taxrules only
   *
   * @returns {Query}
   */
  static taxrules() {
    return new Query('taxrule', ...arguments)
      ._setIndex(allIndicesWildcard)
  }

  /**
   * Creates new query for product group only
   *
   * @returns {Query}
   */
  static productGroup() {
    return new Query('product_group', ...arguments)
      ._setIndex(productGroupIndex)
  }

  /**
   * Creates new query for marketplace sellers only
   *
   * @returns {Query}
   */
  static sellers() {
    return new Query('sellers', ...arguments)
      ._setIndex(marketplaceSellersIndex)
  }

  /**
   * Creates new query for product group only
   *
   * @returns {Query}
   */
  static filterPage() {
    return new Query('filter_page', ...arguments)
      ._setIndex(filterPageIndex)
  }

  /** Limits results to only records where 'field' is equal to 'value'
   * .where('id', 3) will return only records whose id is 3
   *
   * @param {string} field
   * @param {boolean|number|string} value
   * @returns {Query}
   */
  where(field, value) {
    if (isNullish(value)) {
      return this.notExists(field)
    }

    this._must.push({
      term: {
        [field]: {
          value,
        },
      },
    })

    return this
  }

  /**
   * Limits results to only records where 'field' should match 'value'
   *
   *
   * @param {string} field
   * @param {boolean|number|string} value
   * @returns {Query}
   */

  whereShould(field, value) {
    if (isNullish(value)) {
      return this.notExists(field)
    }

    this._should.push({
      term: {
        [field]: {
          value,
        },
      },
    })

    return this
  }

  /**
   * Limits results to only records where 'field' is not equal to 'value'
   * .whereNot('id', 3) will return only records whose id is not 3
   *
   * @param {string} field
   * @param {boolean|number|string} value
   * @returns {Query}
   */
  whereNot(field, value) {
    if (isNullish(value)) {
      return this.exists(field)
    }

    this._must_not.push({
      term: {
        [field]: {
          value,
        },
      },
    })

    return this
  }

  /**
   * Limits results to only records where 'field' is equal to one of the 'values'
   * .whereIn('id', 1, 2, 3) will return only records whose id is 1 or 2 or 3
   * Second parameter can also be array: .whereIn('id', [1, 2, 3])
   *
   * @param {string} field
   * @param {...string|string[]} values
   * @returns {Query}
   */
  whereIn(field, ...values) {
    this._must.push({
      terms: {
        [field]: this._normalizeArgs(values),
      },
    })

    return this
  }

  /**
   * Limits results to only records where 'field' is not equal to any of the 'values'
   * .whereNotIn('id', 1, 2, 3) will return only records whose id is not 1 nor 2 nor 3
   * Second parameter can also be array: .whereNotIn('id', [1, 2, 3])
   *
   * @param {string} field
   * @param {...string|string[]} values
   * @returns {Query}
   */
  whereNotIn(field, ...values) {
    this._must_not.push({
      terms: {
        [field]: this._normalizeArgs(values),
      },
    })

    return this
  }

  /**
   * Limits the results to only records whose 'field' fuzzy matches the 'value'
   *
   * @param {string} field
   * @param {*} value
   * @returns {Query}
   */
  match(field, value) {
    this._match[field] = {
      query: value,
      fuzziness: 'AUTO',
    }

    return this
  }

  /**
   * Adds a nested query to the must for when you need to query within an array of obects of another query
   *
   * @param {string} field
   * @param {Query} query
   * @returns {Query}
   */
  nested(field, query) {
    this._must.push({
      nested: {
        path: field,
        query: query._query(),
      },
    })

    return this
  }

  /**
   * Filters the records based on the passed in script
   * .script("doc['final_price'].value < doc['regular_price'].value") will return records whose final_price is lower than regular_price
   *
   * @param {string} script
   * @returns {Query}
   */
  script(script) {
    this._must.push({
      script: {
        script,
      },
    })

    return this
  }

  /**
   * Limits the results to only records where 'field' is beginning with 'value'
   *
   * Example:
   * .prefix('name', 'Dr. Max')
   *
   * Will return only records with name beginning with 'Dr. Max'
   *
   * @param {string} field
   * @param {boolean|number|string} value
   * @returns {Query}
   */
  prefix(field, value) {
    this._must.push({
      prefix: {
        [field]: {
          value,
        },
      },
    })

    return this
  }

  /**
   * Limits the results to only records containing the 'field' and the field is not null
   *
   * Example:
   * .exits('options')
   *
   * Will return only records where the field 'options' exists and is not null
   *
   * @param {string} field
   * @returns {Query}
   */
  exists(field) {
    this._must.push({
      exists: {
        field,
      },
    })

    return this
  }

  /**
   * Limits the results to only records NOT containing the 'field' or the field is null
   *
   * @param {string} field
   * @returns {Query}
   */
  notExists(field) {
    this._must_not.push({
      exists: {
        field,
      },
    })

    return this
  }

  /**
   * Limits the results to only records where the 'field' is greater than 'value'
   *
   * @param {string} field
   * @param {number|string} value
   * @returns {Query}
   */
  greaterThan(field, value) {
    this._must.push({
      range: {
        [field]: {
          gt: value,
        },
      },
    })

    return this
  }

  /**
   * Limits the results to only records where the 'field' is less than 'value'
   *
   * @param {string} field
   * @param {number|string} value
   * @returns {Query}
   */
  lessThan(field, value) {
    this._must.push({
      range: {
        [field]: {
          lt: value,
        },
      },
    })

    return this
  }

  /**
   * Limits the results to only records where the 'field' is greater than or equal to 'value'
   *
   * @param {string} field
   * @param {number|string} value
   * @returns {Query}
   */
  greaterThanEqual(field, value) {
    this._must.push({
      range: {
        [field]: {
          gte: value,
        },
      },
    })

    return this
  }

  /**
   * Limits the results to only records where the 'field' is less than or equal 'value'
   *
   * @param {string} field
   * @param {number|string} value
   * @returns {Query}
   */
  lessThanEqual(field, value) {
    this._must.push({
      range: {
        [field]: {
          lte: value,
        },
      },
    })

    return this
  }

  /**
   * Limits the results to only records where the 'field' is less than or equal 'high' and lower than 'low'
   *
   * @param {string} field
   * @param {number} low
   * @param {number} high
   * @returns {Query}
   */
  inRange(field, low, high) {
    this._must.push({
      range: {
        [field]: {
          gte: low,
          lte: high,
        },
      },
    })

    return this
  }

  /**
   * Returns records starting from this index
   * It is used with pagination to skip x pages
   * .from(24) will return records with index 24 or more (in other way it skips first 24 records)
   *
   * @param {number|string} from
   * @returns {Query}
   */
  from(from) {
    this._from = parseInt(from) || 0

    return this
  }

  /**
   * Sorts result by the 'field' and 'order'
   *
   * @param {string} field
   * @param {string} [order=asc]
   * @returns {Query}
   */
  sort(field, order = 'asc') {
    this._sort.push({
      [field]: {
        order,
      },
    })

    return this
  }

  /**
   * Allows sorting by nested field while also limiting the possible values with callback
   *
   * Example:
   * Product has field category which is array of categories. Every category has position.
   * We want to sort products by the position of current category represented by categoryId.
   * .sortNested('category.position', 'desc', 'category', q => q.where('category.category_id', categoryId))
   *
   * @param {string} field
   * @param {string} [direction=asc]
   * @param {string} nestedField
   * @param {QueryCallback} callback
   * @returns {Query}
   */
  sortNested(field, direction = 'asc', nestedField, callback) {
    const nestedQuery = Query.any()

    callback(nestedQuery)

    this._sort.push({
      [field]: {
        order: direction,
        nested_path: nestedField,
        nested_filter: nestedQuery._query(),
      },
    })

    return this
  }

  /**
   * Sorting fields by script resolving value
   * @param {string} source
   * @param {string}type
   * @param {'asc'|'desc'} order
   * @param {Object|[]} params
   * @returns {Query}
   */
  sortByScript(source, { type = 'number', order = undefined, params = {} }) {
    this._sort.push({
      _script: {
        type,
        ...(isString(order) ? { order } : {}),
        script: {
          source,
          ...(Object.keys(params).length ? { params } : {}),
        },
      },
    })

    return this
  }

  /**
   * Sorts by field in order defined by values. Values not specified in the values array will be last.
   *
   * Example:
   * We want products with PIM status Available first, then Visible, than Temporary unavailable and after that it doesn't matter.
   *
   * .sortByValues('drmax_pim_status', ['Available', 'Visible', 'Temporary unavailable'])
   *
   * @param {string} field
   * @param {(string|string[])[]} values
   * @returns {Query}
   */

  sortByValues(field, values) {
    values = ensureArray(values)
    this.sortByScript(
      `params.factor[doc['${field}'].value] ?: ${values.length}`, {
        params: {
          factor: values.reduce((factor, value, i) => ({
            ...factor,
            ...ensureArray(value).reduce((o, v) => ({
              ...o,
              [v]: i,
            }), {}),
          }), {}),
        },
      },
    )

    return this
  }

  /**
   * Sorts by field in order of being higher than the given threshold.
   *
   * Example:
   * We want to sort by saleable quantity > 0
   */
  sortByHigherThan(field, threshold = 0, order = 'desc') {
    this.sortByScript(`(doc.containsKey('${field}') && doc['${field}'].value > ${threshold}) ? 1 : 0`, {
      order,
    })

    return this
  }

  /**
   * Adds aggregation by 'field' limited to 'size' amount of results.
   * By defualt it does unique aggregate, so .aggregate('brand', 10) will return maximum of 10 brands
   * and each having a count of how many times the brand is present in the result set.
   *
   * @param {string} field
   * @param {number} [size=25]
   * @returns {Query}
   */
  aggregate(field, size = 25) {
    this._aggs[field] = {
      terms: {
        field,
        size,
      },
    }

    return this
  }

  /**
   * Adds aggregation by 'field' split into range buckets
   * By defualt it does unique aggregate, so .aggregate('brand', 10) will return maximum of 10 brands
   * and each having a count of how many times the brand is present in the result set.
   *
   * @param {string} field
   * @param {Array<Object>} ranges - array of objects with keys 'from' and 'to'
   * @returns {Query}
   */
  aggregateRange(field, ranges) {
    this._aggs[field] = {
      range: {
        field,
        ranges,
      },
    }

    return this
  }

  /**
   * Adds aggregation function, e.g. min or max and names the result adding _{function} to the field name
   *
   * @param fn
   * @param field
   * @returns {Query}
   */

  aggregateFunction(fn = 'max', field = 'final_price') {
    const outputFieldName = `${field}_${fn}`

    this._aggs[outputFieldName] = {}
    this._aggs[outputFieldName][fn] = {
      field,
    }

    return this
  }

  /**
   * Limits the fields which are returned.
   * This can radically decrease size of the response if you only care about few fields
   * .only('id', 'name', 'description') will return only id, name and description and omit other fields form the response
   * First parameter can also be array: .only(['id', 'name', 'description']))
   *
   * @param {...string|[string]} fields
   * @returns {Query}
   */
  only(...fields) {
    fields = this._normalizeArgs(fields)

    this._only = fields

    return this
  }

  /**
   * Limits the fields which are returned.
   * This can decrease size of the response by omitting few fields
   * .omit('id', 'name', 'description') will omit id, name and description and return other fields form the response
   * First parameter can also be array: .only(['id', 'name', 'description']))
   *
   * @param {...string|[string]} fields
   * @returns {Query}
   */
  omit(...fields) {
    fields = this._normalizeArgs(fields)

    this._omit = fields

    return this
  }

  /**
   * Add subquery which should be applied to the result set.
   *
   * Example:
   * If record with ID of 10 exists, it should be added to the result set, otherwise not.
   * .should(q => q.where('id', 10))
   *
   * @param {QueryCallback} callback
   * @returns {Query}
   */
  should(callback) {
    const should = Query.any()

    callback(should)

    this._should.push(should._query())

    return this
  }

  /**
   * Add subquery which must be applied to the result set.
   *
   * Example:
   * Only record with ID of 10 must be in the result set.
   * .must(q => q.where('id', 10))
   *
   * .should and .must can be combined to imitate AND/OR functionality
   *
   * Example:
   * We want records with IDs 10 or 20 and names 'A' or 'B'
   * .must(q => {
   *   q.should(q => q.where('id', 10))
   *   q.should(q => q.where('id', 20))
   * })
   * .must(q => {
   *   q.should(q => q.where('name', 'A'))
   *   q.should(q => q.where('name', 'B'))
   * })
   *
   * @param {QueryCallback} callback
   * @returns {Query}
   */
  must(callback) {
    const must = Query.any()

    callback(must)

    this._must.push(must._query())

    return this
  }

  /**
   * Add subquery which must NOT be applied to the result set.
   *
   * @param {QueryCallback} callback
   * @returns {Query}
   */
  mustNot(callback) {
    const mustNot = Query.any()

    callback(mustNot)

    this._must_not.push(mustNot._query())

    return this
  }

  /**
   * Add named subquery which can be later removed with .without
   * If the subquery is not removed with .without call, it beahaves exactly same as normal query.
   *
   * Example:
   * const query = Query.products().named('brandFilter', q => q.where('drmax_brand', 1))
   *
   * @param {string} name
   * @param {QueryCallback} callback
   * @returns {Query}
   */
  named(name, callback) {
    const query = Query.any()

    callback(query)

    this._named[name] = query

    return this
  }

  /**
   * Removed named subquery
   *
   * Example:
   * const products = await query.without('brandFilter').get()
   *
   * This will remove '.where('drmax_brand', 1)' filter.
   *
   * @param {string} name
   * @returns {Query}
   */
  without(name) {
    delete this._named[name]

    return this
  }

  /**
   * Limits the number of results to 'size'
   *
   * @param {number|string} size
   * @returns {Query}
   */
  size(size) {
    this._size = parseInt(size) || 0

    return this
  }

  /**
   * Add optional subquery
   *
   * Example:
   * .when(something === true, query => query.where('foo', 'bar'))
   *
   * Will add the .where clause only when 'something' is equal to true
   *
   * @param {boolean} condition
   * @param {QueryCallback} callback
   * @returns {Query}
   */
  when(condition, callback) {
    if (condition) {
      // eslint-disable-next-line n/no-callback-literal
      callback(this)
    }

    return this
  }

  /**
   * Finalizing method of the Query builder
   * Builds the query and fetches the records from elasticsearch
   * It returns plain array of records
   * Default number of returned records is 10 and can be change with the 'size' parameter
   *
   * @param {number|null} [size=null]
   * @returns {Promise<any[]>}
   */
  async get(size = null) {
    if (size != null) {
      this.size(size)
    }

    const response = await this.fetch()

    return response.hits.hits.map((hit) => hit._source)
  }

  /**
   * Finalizing method of the Query builder
   * Builds the query and fetches the records from elasticsearch
   * It returns plain array of hits, together with their type
   * Default number of returned records is 10 and can be change with the 'size' parameter
   *
   * @param {number|null} [size=null]
   * @returns {Promise<any[]>}
   */
  async getRaw(size = null) {
    if (size != null) {
      this.size(size)
    }

    const rawResponse = await this.fetch()
    const response = rawResponse.hits.hits.map((hit) => ({
      index: hit._index,
      type: hit._type,
      source: hit._source,
    }))
    return response
  }

  /**
   * It returns array of hits and total number of hits
   *
   * @param {number|null} [size=null]
   * @returns {Promise<Object>}
   */
  async getWithTotal(size = null) {
    if (size != null) {
      this.size(size)
    }

    const response = await this.fetch()

    return {
      hits: response.hits.hits.map((hit) => hit._source),
      total: response.hits.total?.value ?? response.hits.total,
    }
  }

  /**
   * @returns {Promise<any[]>}
   */
  getAll() {
    return this.get(9001)
  }

  /**
   * Finalizing method of the Query builder
   * Builds the query and fetches the records from elasticsearch
   * It returns the first plain record
   *
   * @returns {Promise<any>}
   */
  async first() {
    const response = await this.fetch(1)

    return response.hits.hits.length >= 1 ? response.hits.hits[0]._source : null
  }

  /**
   * Finalizing method of the Query builder
   * Builds the query and returns only aggregations returned from the elasticsearch
   * If 'aggregation' argument is set, it returns only this aggregation
   */
  async aggregations(aggregation = null) {
    const result = await this.fetch(0)

    if (aggregation === null) {
      return result.aggregations
    }

    return result.aggregations[aggregation] ? result.aggregations[aggregation].buckets : []
  }

  async paginate(size = null) {
    const response = await this.fetch(size)

    return {
      items: response.hits.hits.map((hit) => hit._source),
      total: response.hits.total?.value ?? response.hits.total,
      perPage: this._size,
      currentPage: (this._from / this._size) + 1,
    }
  }

  /**
   * Finalizing method of the Query builder
   * Builds the query and returns raw response from the elasticsearch
   *
   * @param {number} [size=null]
   * @returns {Promise<any>}
   * @throws {Error}
   */
  async fetch(size = null) {
    if (size !== null) {
      this.size(size)
    }

    const index = this._index
    const type = this._getType()
    const body = this._build()

    return this._fetch(
      `${appUrlInternal || this._appUrl}/_e`,
      createQueryString({
        index,
        body,
        ...(type ? { type } : {}),
      }),
    )
  }

  async _fetch(url, body) {
    try {
      const headers = {
        'Content-Type': 'application/json',
        // cloudflare stage protection bypass
        ...(accessClientId
          ? {
              'CF-Access-Client-Id': accessClientId,
              'CF-Access-Client-Secret': accessClientSecret,
            }
          : {}),
      }

      const response = body.length < 2048
        ? await this._fetchHandler.call(globalThis, `${url}?${body}`, {
          headers,
        })
        : await this._fetchHandler.call(globalThis, url, {
          headers,
          method: 'POST',
          mode: 'cors',
          cache: 'no-cache',
          credentials: 'same-origin',
          redirect: 'follow',
          referrerPolicy: 'no-referrer',
          body,
        })

      if (response.status !== 200 || !response.ok) {
        logger.error({
          index: qs.parse(body).index,
          ok: response.ok,
          status: response.status,
          statusText: response.statusText,
        })
        const error = new Error(response.statusText)
        error.code = response.status
        throw error
      }

      const json = await response.json()

      if (json.error) {
        throw new Error(json.error)
      }

      return json
    } catch (e) {
      logger.error('Error while fetching data from ElasticSearch: %o', e.message)

      return {
        aggregations: {},
        hits: {
          hits: [],
          total: 0,
        },
      }
    }
  }

  /**
   * @returns {string}
   */
  toString() {
    return JSON.stringify({ ...this })
  }

  /**
   * @param {string} string
   * @returns {Query}
   */
  static fromString(string) {
    return Object.assign(new Query(), JSON.parse(string))
  }

  /**
   * Creates clone of the query
   *
   * @returns {Query}
   */
  clone() {
    const query = new Query(this._type)

    query._size = this._size
    query._from = this._from

    query._named = { ...this._named }

    query._sort = [...this._sort]
    query._only = [...this._only]
    query._omit = [...this._omit]

    query._should = [...this._should]
    query._must = [...this._must]
    query._must_not = [...this._must_not]

    query._match = { ...this._match }

    query._aggs = { ...this._aggs }
    query._index = this._index

    return query
  }

  _dump() {
    let out = ''

    const index = (onlyOneElasticIndex || apiIndex || '').split('/').pop()

    out += `GET ${index}${this._type ? `/${this._type}` : ''}/_search`
    out += '\n'
    out += JSON.stringify(this._build(), null, 2)

    // eslint-disable-next-line no-console
    console.log(out)

    return this
  }

  _build() {
    const body = {
      _source: {
        includes: this._only,
        excludes: this._omit,
      },
      size: this._size,
      from: this._from,
      sort: this._sort,
      aggs: this._aggs,
      // does not add empty query object if its actually empty
      // newer verison of ES throws exception if is present
      ...(isEmpty(this._query()) ? {} : { query: this._query() }),
    }

    // Allow getting total hits over the Elastic limit (since Elastic 7 - in context of NSF effectively the same condition as splittedIndiciesEnabled)
    if (this._splittedIndiciesEnabled) {
      body.track_total_hits = true
    }

    return body
  }

  _query() {
    const must = this.getMust()
    const mustNot = this.getMustNot()
    const should = this.getShould()
    const match = this.getMatch()

    const hasBoolQuery = must.length > 0 || mustNot.length > 0 || should.length > 0
    const hasMatchQuery = Object.keys(match).length > 0

    return {
      ...(hasBoolQuery
        ? {
            bool: {
              ...(must.length > 0 ? { must } : {}),
              ...(mustNot.length > 0 ? { must_not: mustNot } : {}),
              ...(should.length > 0 ? { should } : {}),
            },
          }
        : {}),
      ...(hasMatchQuery
        ? {
            match,
          }
        : {}),
    }
  }

  _normalizeArgs(args) {
    if (args.length === 1 && Array.isArray(args[0])) {
      return args[0]
    }

    return args
  }

  /**
   * Returns array of should conditions
   */
  getShould() {
    return [...this._should, ...flatMap(Object.values(this._named), (named) => named._should)]
  }

  /**
   * Returns array of must conditions
   */
  getMust() {
    return [...this._must, ...flatMap(Object.values(this._named), (named) => named._must)]
  }

  /**
   * Returns array of must_not conditions
   */
  getMustNot() {
    return [...this._must_not, ...flatMap(Object.values(this._named), (named) => named._must_not)]
  }

  /**
   * Returns array of match conditions
   */
  getMatch() {
    return Object.assign({}, this._match, ...flatMap(Object.values(this._named), (named) => named._match))
  }

  /**
   * Sets new ES index to search from instead of using default one (single index solution)
   */
  _setIndex(indexName) {
    if (this._splittedIndiciesEnabled) {
      if (Array.isArray(indexName)) {
        this._index = indexName.filter((e) => e).join(',')
      } else {
        this._index = indexName
      }
    }

    return this
  }

  /**
   * Sets new ES type to search from instead of using default one (single index solution)
   */
  _setType(type) {
    if (!this._splittedIndiciesEnabled) {
      if (Array.isArray(type)) {
        this._type = type.join(',')
      } else {
        this._type = type
      }
    }
    return this
  }

  /**
   * Gets empty _type, only when separated indieces are used (based on configuration)
   */
  _getType() {
    if (this._splittedIndiciesEnabled) {
      return ''
    }

    return this._type
  }
}

/* eslint-enable */
